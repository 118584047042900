import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'
import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const WorkflowForConstructionSite: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAX presented unified data workflow for construction"
        description="2-days Construction Technology Festival KSA took place in Riyadh, October, 23-24."
      />
      <NewsSectionBlock date="31.10.2023">
        <Typography variant="h1" color="blue">
          SIGNAX presented unified data workflow for construction site at CTF
          2023 Riyadh
        </Typography>
        <Typography variant="body1">
          The annual Construction Technology Festival took place in Crowne Plaza
          Riyadh| RDC Hotel, October 23-24.
        </Typography>
        <Typography variant="body1">
          The event was a big success, attracting over 600 attendees, featuring
          more than 80 speakers, and hosting more than 50 exhibitors. Speakers
          formed 2 conference tracks focused on 4 major themes: Digital &
          Innovative Delivery, Data Intelligence, Sustainability, and
          Smart/Future Cities.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/workflow-for-construction-site/image-1.jpg"
            alt="SIGNAX team and stand"
            title="SIGNAX team and stand"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.1 SIGNAX team and stand
          </Typography>
        </div>
        <Typography variant="body1">
          SIGNAX presented the future of a digitized job site. It is connected
          not with a single technology that solves the specific customer’s task
          but with the synergy of digital services: laser scanning, drone
          survey, photo 360, and, of course, onsite BIM & cloud CDE that form
          the digital data workflow needed for enhanced collaboration and a
          single source of truth creation for all construction works.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/workflow-for-construction-site/image-2.jpg"
            alt="Petr Manin, Regional director, presenting SIGNAX DOCS module"
            title="Petr Manin, Regional director, presenting SIGNAX DOCS module"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.2 Petr Manin, Regional director, presenting SIGNAX DOCS module
          </Typography>
        </div>
        <Typography variant="body1">
          SIGNAX DOCS module was shown for the first time. This brand-new Common
          data environment (CDE) has everything as the leading document flow
          systems: support of DWG and model and office formats; versions,
          issues, viewer, and more. And 3 unique things:
        </Typography>
        <ol className={cn(s.textLists, s.decimal)}>
          <li>SIGNAX DOCS works on the client’s servers, «on-premise»;</li>
          <li>
            The mobile version is something incredible with a user-friendly
            interface and working at any place;
          </li>
          <li>The cost is just 100$.</li>
        </ol>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/workflow-for-construction-site/image-3.png"
            alt="SIGNAX Docs is ready to use and has a price of 100$"
            title="SIGNAX Docs is ready to use and has a price of 100$"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.3 SIGNAX Docs is ready to use and has a price of 100$
          </Typography>
        </div>
        <Typography variant="body1">
          As usual, SIGNAX stand attracted innovators from leading companies in
          Saudi Arabia. Many visitors asked how the BIM model can be used on
          construction site and found answers in SIGNAX solutions presented
          there. SIGNAX made a great number of 1-to-1 meetings with C-level
          stakeholders and is ready to start new projects in the region.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={580}
            src="../assets/images/news/workflow-for-construction-site/image-4.jpg"
            alt="Each meeting gave a valuable conversation in terms of construction site digital development"
            title="Each meeting gave a valuable conversation in terms of construction site digital development"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Fig.4 Each meeting gave a valuable conversation in terms of
            construction site digital development
          </Typography>
        </div>
        <Typography variant="body1">
          It is a great pleasure for us to participate and play an important
          role in developing technologies in Saudi Arabia and the whole MENA
          region!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default WorkflowForConstructionSite
